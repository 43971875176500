<template>
  <div>
    <common-header :title="title"></common-header>
    <div class="warp">
      <div class="sarch-big-box">
        <div class="search-box">
          <input class="search-input" type="text" v-focus="autoInput" placeholder="搜索商品" v-model="searchVal">
          <i class="icon-search-fdj"></i>
          <img class="clear-input" @click="clearInput" v-if="searchVal != ''" src="@/assets/images/img/clear-input.png" alt="">
        </div>
        <div class="search-btn jb-r-l" @click="btnSearch">搜索</div>
      </div>
      <ul class="search-res"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10">
        <li class="item" v-for="(item, index) in searchDa.list" :key="index" @click="$router.push(`/shopping/details?id=${item.id}`)">
          <img class="goods-img" :src="getGoodsImg(item)" alt="">
          <div class="content">
            <div class="name">{{item.name}}</div>
            <div class="ms" v-if="!item.specList[0].seckill && item.specList[0].minLimit == 1 && !item.tags ">{{item.description}}</div>
            <div class="tags-box" v-else>
              <div class="tag m-kill">月销{{item.virtualMonthlySales + item.monthlySales}}</div>
              <div class="tag m-kill" v-if="item.specList[0].seckill">秒杀</div>
              <template v-if="item.tags">
                <div class="tag tags-item"  v-for="(tagItem, tagIndex) in (item.tags).split(',')" :key="tagIndex">
                  {{tagItem}}
                </div>
              </template>
              <div class="tag buy-from" v-if="item.specList[0].minLimit > 1">{{item.specList[0].minLimit}}{{item.unitName}}起购</div>
            </div>
            <div class="price-box">
              <div class="price">
                <span class="unit">￥</span>{{item.unitPrice}}
              </div>
              <div class="icon-car-btn" @click.stop="SelectProducts(item.id, item.specList[0].id, item.inventory)"></div>
            </div>
          </div>
        </li>
      </ul>
      <no-data v-if="nodata"></no-data>
      
      <!-- 热门搜索 start -->
      <!-- <div class="search-his">
        <div class="title">热门搜索</div>
        <ul class="his-list">
          <li class="item">茅台1499</li>
          <li class="item">茅台1499</li>
        </ul>
      </div> -->
      <!-- 热门搜索 end -->

      <!-- 热门搜索 start -->
      <!-- <div class="search-his" v-if="!searchDa.list">
        <div class="title">历史搜索</div>
        <ul class="his-list">
          <li class="item">茅台1499</li>
          <li class="item">茅台1499</li>
        </ul>
      </div> -->
      <!-- 热门搜索 end -->
    </div>
    <tabBar></tabBar>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState  } from 'vuex';
import { requestImgUrl, getGoodsImg, CountDown  } from "@/utils/common";
export default {
  data() {
    this.getGoodsImg = v => getGoodsImg(v);
    return {
      searchVal:'',
      searchDa: [],
      nodata: false,
      autoInput: true,
      loading: true,
      nomore: false,
      uid: localStorage.getItem('uid'),
      name: "",
      tagsCode: "",
      // 标题
      title: "",
      pageNum: 1,
    }
  },
  created() {
    let name = this.$route.query.name;
    let title = this.$route.query.title;
    let tagsCode = this.$route.query.tagsCode;
    this.name = name;
    this.tagsCode = tagsCode;
    if (title) {
      this.title = title;
    }
    if (name) {
      this.searchVal = name;
    }
    this.autoInput = false;
    this.init({});
  },
  directives: {
    focus: {
      inserted: function (el, {value}) {
        if (value) {
          el.focus()
        }
      }
    }
  },
  methods: {
    // 清空
    clearInput() {
      this.searchVal = "";
      this.btnSearch();
    },
    // 搜索接口
    init(obj) {
      this.GetCommodityList({
        searchKey: obj.name,
        tagsCode: obj.tagsCode,
        ordered: 1,
        pageNum: obj.pageNum ? obj.pageNum : 1,
        pageSize: 30,
      }).then((res)=> {
        this.loading = false;
        let searchDa = this.searchDa;
        if (obj.pageNum) {
          searchDa.list = searchDa.list.concat(res.data.list);
        } else {
          this.searchDa = res.data;
          this.nodata = res.data.total > 0 ? false : true;
        }
      })
    },
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      this.pageNum = pageNum;
      if (pageNum > this.searchDa.pages) {
        this.nomore = true;
      } else {
        if (this.searchVal != '') {
          this.init({
            name: this.searchVal, 
            pageNum: pageNum,
          });
        }
      }
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if(inventory <= 0) {
        this.$toast("卖光啦！");
        return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid,
      }).then((res)=> {
        if (res.status == 200) {
          this.$toast("加入购物车成功")
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then((res)=> {
            this.goodsNumMuta(res.data.quantity)
          })
        }
      })
    },
    btnSearch() {
      this.init({
        name: this.searchVal,
        tagsCode: this.tagsCode,
      });
    },
    ...mapMutations(["goodsNumMuta"]),
    ...mapActions('shopping', [
      'GetCommodityList',
      'getCommodityCount',
      'AddCommodityCart',
    ])
  },
  computed: {
    ...mapState(["orgInfo", "goodsNum"]),
  },
}
</script>
<style lang='less' scoped>
.clear-input{
  position: absolute;
  top: 50%;
  right: .2rem;
  margin-top: -.2rem;
  width: .4rem;
  height: .4rem;
}
.gwc-box{
  position: fixed;
  bottom: 10%;
  left: .4rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ff7141;
  display: flex;
  align-items: center;
  justify-content: center;
  .badge{
    position: absolute;
    top: -0.05rem;
    right: 0;
    font-size: 0.24rem;
    transform: translateX(40%);
  }
  img{
    width: 55%;
  }
}
.price-box{
  display: flex;
  justify-content: space-between;
  .price{
    display: flex;
    color: #ff7241;
    font-size: 0.32rem;
    align-items: center;
    font-weight: bold;
    .unit{
      font-size: 0.24rem;
    }
  }
}
.search-res{
  margin-top: .2rem;
  .content{
    flex: 1;
    .name{
      font-size: 0.3rem;
      color: #262626;
      font-weight: bold;
    }
    .ms{
      font-size: 0.26rem;
      color: #bfbfbf;
      margin-top: 0.1rem;
      margin-bottom: 0.25rem;
    }
  }
  .item{
    background: #fff;
    border-radius: 0.1rem;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.15rem;
    overflow: hidden;
    margin-bottom: 0.2rem;
  }
  .goods-img{
    position: relative;
    width: 1.52rem;
    height: 1.52rem;
    border-radius: 0.05rem;
    margin-right: 0.2rem;
  }
}
.his-list{
  margin-top: .4rem;
  display: flex;
  flex-wrap: wrap;
  .item{
    margin-right: .2rem;
    font-size: .26rem;
    color: #595959;
    background: #FFF;
    padding: .12rem .32rem;
    border-radius: .25rem;
    margin-bottom: .2rem;
  }
}
.search-his{
  margin-top: .5rem;
  .title{
    font-size: .3rem;
    font-weight: bold;
  }
}
.sarch-big-box{
  display: flex;
  padding-top: .2rem;
  .search-btn{
    color: #fff;
    min-width: 1.2rem;
    margin-left: .2rem;
    font-size: .28rem;
    text-align: center;
    line-height: .68rem;
    border-radius: 0.3rem;
  }
}
.search-box{
  width: 100%;
  height: .68rem;
  background: #fff;
  border-radius: .3rem;
  position: relative;
  .icon-search-fdj{
    position: absolute;
    top: 50%;
    left: .2rem;
    transform: translateY(-50%);
  }
  .search-input{
    width: 100%;
    height: 100%;
    font-size: .26rem;
    text-indent: .6rem;
  }
}
</style>